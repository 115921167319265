.login-form {
    position: fixed;
    top:40%;
    left:50%;
    transform: translate(-50%, -50%);
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
}

.login-submit {
    margin-top: 15px;
}