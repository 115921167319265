.certificateView .MuiTableCell-root {
    padding: 5px !important
}

.certificateView .MuiInput-formControl {
    margin-top: 5px !important
}

.attendanceMonitoring .MuiTableCell-root {
    padding: 5px !important
}

.attendanceMonitoring .MuiInput-formControl {
    margin-top: 5px !important
}